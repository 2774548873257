/* ============================= */
/* NAVIGATION BAR */
/* ============================= */
img.brandName {
    width: 120px;
    height: auto;
    padding-top: 2px;
    margin-left: 5.5em;
    background-color: #fedcdb;
    border-radius: 40%;
}
p.offcanvas-texts {
    font-size: 20px;
}
div.offcanvas-title {
    color: #af3753;
}
#basic-nav-dropdown {
    color: #af3753;
}
a.dropdown-item {
    color: #af3753;
}
p.contactme-text {
    margin-bottom: 0;
    border: 1.5px #af3753 solid;
    padding: 4px 10px;
    font-size: 15px;
    color: #af3753;
    float: right;
}
p.contactme-text:hover {
    background-color: #af3753;
    color: white;
    border-color: #af3753;
}
.contactme-icon {
    visibility: hidden;
    font-size: 0px;
    float: right;
    color: #af3753;
}
.menubar {
    font-size: 22px;
    color: #af3753;
    float: left;
}
.menubar:hover {
    transform: scale(1.2);
}
@media screen and (max-width: 767px) {
    p.contactme-text {
        visibility: hidden;
        font-size: 0px;
        border: none;
        padding: 0;
        margin: 0;
    }
    .contactme-icon {
        visibility: visible;
        font-size: 23px;
    }
    img.brandName {
        margin-left: 1em;
    }
}
/* ============================= */
/* ImageSlider */
/* ============================= */
.box-carousel {
    width: 90%;
    margin-left: 5%;
    margin-top: 6em;
}
@media screen and (max-width: 500px) {
    .box-carousel {
        margin-top: 6.5em;
    }
}
/* ============================= */
/* INTRO */
/* ============================= */

div.intro {
    display: grid;
    row-gap: 3em;
    margin-top: 4em;
}
img.selfi {
    height: 360px;
    width: 240px;
}
div.image-box {
    margin: 0 auto;
}

p.intro-heading {
    font-size: 36px;
    font-family: "Cormorant Garamond", serif;
    font-weight: 600;
}
p.intro-desc {
    margin-top: 35px;
    font-family: "Source Serif 4", sans-serif;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 13; /* number of lines to show */
    line-clamp: 13;
    -webkit-box-orient: vertical;
}
button.button-more-about-me {
    padding: 0 16px;
    background-color: #af3753;
    border: none;
    border-radius: 0;
    margin-top: 2em;
}
button.button-more-about-me:hover {
    background-color: white;
    border: 1px solid #af3753;
    color: #af3753;
}
p.button-more-about-me:hover {
    color: #af3753;
}
p.button-more-about-me {
    margin: 8px 0;
    color: white;
    font-size: 16.5px;
    font-family: "Source Serif 4";
}
@media screen and (min-width: 767px) {
    div.intro {
        column-gap: 4em;
        display: flex;
        height: 900px;
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    img.selfi {
        height: 480px;
        width: 320px;
        margin-top: 12em;
    }
    div.intro-words {
        margin-top: 5em;
    }
    p.intro-heading {
        font-family: "Times New Roman", Times, serif;
        font-size: 48px;
        line-height: 55px;
    }
    p.intro-desc {
        font-size: 20px;
    }
}
@media screen and (max-width: 500px) {
    div.intro {
        display: grid;
        row-gap: 3em;
        margin-top: 5em;
    }
    p.intro-desc {
        margin-top: 1em;
    }
    img.selfi {
        margin-top: 3.5em;
    }
}
@media screen and (max-width: 766px) {
    div.intro {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 3em;
    }
    div.intro-words {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }
}
/* For Ipad screens */
@media screen and (min-width: 766px) and (max-width: 823px) {
    div.intro {
        margin-bottom: 10em;
    }

    img.selfi {
        height: 480px;
        width: 320px;
        margin-top: 16em;
    }
}
/* ============================= */
