div.thankyou-page {
    margin-top: 0;
    height: 100rem;
    background-color: #fedcdb;
}
p.thankyou {
    font-size: 18vw;
    color: #af3753;
    font-family: "Dancing Script", cursive;
    text-align: center;
    padding-top: 30vw;
}
p.thankyou-for {
    font-size: 8vw;
    margin-top: -30px;
    font-family: "Dancing Script", cursive;
    color: #af3753;
    text-align: center;
}
p.return-main-page {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2.5vw;
    text-align: center;
    margin-top: 10vw;
    text-transform: capitalize;
}
div.makeuplogo {
    width: 100%;
    display: flex;
    justify-content: center;
}
img.makeuplogo1 {
    width: 50vw;
    border-radius: 45%;
}
@media (min-width: 600px) {
    p.thankyou {
        margin-top: 80px;
        font-size: 120px;
    }
    p.thankyou-for {
        font-size: 60px;
    }
    p.return-main-page {
        font-size: 17px;
    }
    img.makeuplogo1 {
        width: 140px;
    }
}
