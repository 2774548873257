/* Heading and sub-heading */
div .contact-page-title {
    font-size: 40px;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    color: #edbbbf;
    text-shadow: 0 0 1vw black;
    margin-top: 30px;
}
p.for-inquiries {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    text-align: center;
}

/* social media */
div.page-insta {
    text-align: center;
}
div.page-insta:hover {
    transform: scale(1.3);
}
a.page-insta {
    text-decoration: none;
    color: #af3753;
    font-size: 18px;
}

/* form styling */
form.contact-me {
    display: grid;
    margin-bottom: 30%;
}
input.input {
    margin: 5px 0;
    padding: 5px 10px;
}
input.input:active {
    border: 2px solid #af3753;
}

textarea.textarea {
    margin: 5px 0;
    padding: 5px 10px;
}
button.submit-button {
    margin: 5px 0;
    padding: 10px;
    color: white;
    background-color: #af3753;
    border: none;
    border-radius: 5px;
}
button.submit-button:hover:active {
    background-color: white;
    color: #af3753;
    border: 1px solid #af3753;
}

/* for larger screens */
@media (min-width: 700px) {
    div .contact-page-title {
        font-size: 80px;
    }
    p.for-inquiries {
        font-size: 20px;
    }
}

/* for smaller screen */
@media (max-width: 700px) {
    form.contact-me {
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
}
