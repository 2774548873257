.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;
    margin-top: 1.5em;
}
div.gallery-word {
    margin-top: 4em;
    text-align: center;
    font-size: 48px;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 600;
}
.gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}
.gallery .pics:hover {
    filter: opacity(0.8);
}
@media (max-width: 500px) {
    .gallery {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
    div.gallery-word {
        margin-top: 2em;
        text-align: center;
        font-size: 33px;
        font-family: "Cormorant Garamond", serif;
        font-style: italic;
        font-weight: 600;
    }
}
@media (min-width: 900px) {
    div.gallery-word {
        margin-top: 0;
    }
}
