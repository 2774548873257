p.faq-word {
    margin-top: 3em;
    text-align: center;
    font-size: 48px;
    font-family: "Cormorant Garamond", serif;
    font-style: italic;
    font-weight: 600;
}
div.comment-box {
    background-color: #fedcdb;
    height: 22em;
}
div.review-box {
    height: 22em;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}
div.question {
    margin-left: 2em;
    margin-right: 2em;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    color: #434242;
    text-transform: capitalize;
}
div.answer {
    text-transform: capitalize;
    margin-top: 10px;
    margin-left: 4em;
    margin-right: 4em;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 200;
    color: #3b3b3bd6;
}
a.carousel-control-prev {
    margin-left: 0;
}
div.carousel-indicators {
    visibility: hidden;
}
/* mobile Screens */
@media screen and (max-width: 550px) {
    p.faq-word {
        margin-top: 2em;
    }
    div.comment-box {
        height: 16em;
    }
    div.review-box {
        height: 16em;
    }
    div.question {
        font-size: 17px;
    }
    div.answer {
        font-size: 13px;
    }
}
