.footer {
    background-color: #fedcdb;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 9em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #af3753;
    letter-spacing: 1px;
}
.footer-left {
    font-family: "Prompt", sans-serif;
    margin-left: 10vw;
    margin-top: 0.8em;
}
p.footer-copyright {
    margin-bottom: -0.2em;
    font-size: 2.5vw;
}
p.footer-website-name {
    font-size: 3.5vw;
}
.footer-right {
    margin-top: 0.8em;
    margin-right: 10vw;
    font-family: "Prompt", sans-serif;
}
p.footer-social {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    margin-top: -0.7em;
    font-size: 3.5vw;
}
a {
    align-self: center;
}
.social-icons {
    color: #af3753;
    font-size: 3.7vw;
}
.social-icons:hover {
    transform: scale(1.3);
}

@media (min-width: 700px) {
    p.footer-social {
        font-size: 20px;
    }

    p.footer-website-name {
        font-size: 20px;
    }
    p.footer-copyright {
        font-size: 18px;
    }
    .social-icons {
        font-size: 22px;
    }
}
